import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
    
      <section className='hero'>
      <div className="herooverlay">
        <div className='container'>
          <div className='row'>
            <Heading subtitle='EDUCHANNEL' title='Pioneering IT Learning for the Next Generation' />
            <p>Innovating IT Training for Tomorrow’s Leaders and Shaping the Future of IT Learning Today.</p>
            <div className='button'>
              <button className='primary-btn'>
                GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>
              </button>
              <button>
                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            </div>
          </div>
        </div>
        </div>
      </section>
      <div className='margin'></div>
      
    </>
  )
}

export default Hero
